const addDays = (date: string, days: number) => {
  const newDate = new Date(date)
  newDate.setDate(newDate.getDate() + days)
  return newDate
}

export default (context: any) => {
  class ResourceService {
    private static resourcesMethods = context.$api.resources

    private static resourcesAdapters = context.$adapters.resources

    private static constants = context.$config.constants

    async getResourcesByUser({ dateFrom = '', dateLimit = 15, page = 1, limit = 7 }) {
      const auth = await context.$services.useAPI.request({
        config: {
          customServer: 'https://internal-structure.inet.digital/api/inetstudio/acl-package/v1/login',
          method: 'POST'
        },
        body: {
          login: 'admin@local.com',
          password: 'password'
        }
      })

      const access = auth?.data?.access_token
      localStorage.setItem('access', access)

      const result = await ResourceService.resourcesMethods.getResourcesByUser({
        date_gte: new Date(dateFrom).getTime() / 1000,
        date_lte: new Date(addDays(dateFrom, dateLimit)).getTime() / 1000,
        page,
        limit
      })

      const resources = result?.data?.data || result?.data || []

      if (resources.length) return ResourceService.resourcesAdapters.getResourcesByUser(resources, dateFrom, dateLimit)

      return []
      return ResourceService.resourcesAdapters.getResourcesByUser(
        ResourceService.constants.fakeResourcesByUser,
        dateFrom,
        dateLimit
      )
    }
  }

  context.$services.resource = new ResourceService()
}
