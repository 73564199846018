import GSTCApi from 'gantt-schedule-timeline-calendar/dist/gstc.wasm.esm.min.js'

const correctDate = (date = '') => {
  const array = date.split('-')
  return `${array[2]}-${array[1]}-${array[0]}`
}

const getChartItemObj = (id: any, start: any, end: any, rowId: any, hours: any) => {
  const background =
    hours > 0 && hours < 6 ? '#F1A255' : hours >= 6 && hours <= 8 ? '#16A086' : hours > 8 ? '#C1392B' : '#BEC3C7'

  return {
    id,
    time: {
      start,
      end
    },
    style: {
      background
    },
    rowId,
    description: hours
  }
}

const addZero = (value: any) => (value < 10 ? `0${value}` : value)

const addDays = (date: any, days: any) => {
  const newDate = new Date(date)
  newDate.setDate(newDate.getDate() + days)
  return newDate
}

const getDates = (startDate: any, stopDate: any) => {
  const allDates = []
  let currentDate = startDate

  while (currentDate <= stopDate) {
    const year = currentDate.getFullYear()
    const month = addZero(currentDate.getMonth() + 1)
    const day = addZero(currentDate.getDate())
    const date = `${year}-${month}-${day}`
    const gstcDate = GSTCApi.api.date(date).add(Math.floor(Math.random() * 1), 'day')

    const startDate = gstcDate.startOf('day').valueOf()
    const endDate = gstcDate.endOf('day').valueOf()

    allDates.push({
      date,
      startDate,
      endDate
    })

    currentDate = addDays(currentDate, 1)
  }
  return allDates
}

export default (context: any) => {
  const GSTCID = GSTCApi.api.GSTCID
  class ResourcesAdapters {
    async getResourcesByUser(resources = [], dateFrom: any, dateLimit: any) {
      const allDates = getDates(new Date(dateFrom), addDays(new Date(), dateLimit))

      const rows: any = {}
      const items: any = {}

      resources.forEach((user: any) => {
        const userId = GSTCID(String(user.id))
        rows[userId] = {
          id: userId,
          label: user.name
        }

        const userHoursByDate: any = {}
        const userTotalHours: any = []
        user.projects.forEach((project: any) => {
          const projectId = GSTCID(String(`${userId}-${project.id}`))
          rows[projectId] = {
            id: projectId,
            label: project.name,
            deadline: project.deadline,
            parentId: userId
          }

          const projectHoursByDate: any = {}
          const projectTotalHours: any = []
          project.issues.forEach((issue: any) => {
            const issueId = GSTCID(String(`${projectId}-${issue.id}`))
            rows[issueId] = {
              id: issueId,
              label: issue.name,
              deadline: issue.deadline,
              parentId: projectId
            }

            const totalHours: any = []

            allDates.forEach((date) => {
              const timeId = GSTCID(String(`${issueId}-${date.date}`))

              items[timeId] = getChartItemObj(timeId, date.startDate, date.endDate, issueId, 0)
            })

            issue.timesheet.forEach((time: any) => {
              const timeId = GSTCID(String(`${issueId}-${time.date}`))

              const startDayjs: any = GSTCApi.api
                .date(new Date(time.date))
                .add(Math.floor(Math.random() * 1), 'day')
                .startOf('day')
                .valueOf()
              const end: any = GSTCApi.api
                .date(new Date(time.date))
                .add(Math.floor(Math.random() * 1), 'day')
                .endOf('day')
                .valueOf()

              const hours = time.value / 60
              totalHours.push(hours)
              if (!projectHoursByDate[time.date]) {
                projectHoursByDate[time.date] = [hours]
              } else {
                projectHoursByDate[time.date].push(hours)
              }

              items[timeId] = getChartItemObj(timeId, startDayjs, end, issueId, hours)
            })

            rows[issueId].totalTime = totalHours.reduce((a: any, b: any) => a + b, 0)
          })

          allDates.forEach((date) => {
            const projectTimeId = GSTCID(String(`${projectId}-${date.date}`))

            const hours = projectHoursByDate?.[date.date]?.reduce((a: any, b: any) => a + b) || 0
            projectTotalHours.push(hours)

            if (projectHoursByDate[date.date]?.length) {
              items[projectTimeId] = getChartItemObj(projectTimeId, date.startDate, date.endDate, projectId, hours)
            } else {
              items[projectTimeId] = getChartItemObj(projectTimeId, date.startDate, date.endDate, projectId, 0)
            }

            if (!userHoursByDate[date.date]) {
              userHoursByDate[date.date] = [hours]
            } else {
              userHoursByDate[date.date].push(hours)
            }
          })

          rows[projectId].totalTime = projectTotalHours.reduce((a: any, b: any) => a + b, 0)
        })

        allDates.forEach((date) => {
          const userTimeId = GSTCID(String(`${userId}-${date.date}`))

          if (userHoursByDate[date.date]?.length) {
            const hours = userHoursByDate[date.date].reduce((a: any, b: any) => a + b)
            userTotalHours.push(hours)

            items[userTimeId] = getChartItemObj(userTimeId, date.startDate, date.endDate, userId, hours)
          } else {
            items[userTimeId] = getChartItemObj(userTimeId, date.startDate, date.endDate, userId, 0)
          }
        })

        rows[userId].totalTime = userTotalHours.reduce((a: any, b: any) => a + b, 0)
      })

      return {
        rows,
        items
      }
    }
  }

  context.$adapters.resources = new ResourcesAdapters()
}
