<template>
  <div>
    <div id="toolbox"></div>
  </div>
</template>

<script setup>
import GSTC from 'gantt-schedule-timeline-calendar/dist/gstc.wasm.esm.min.js'
import { Plugin as CalendarScroll } from 'gantt-schedule-timeline-calendar/dist/plugins/time-bookmarks.esm.min.js'
import { Plugin as HighlightWeekends } from 'gantt-schedule-timeline-calendar/dist/plugins/time-bookmarks.esm.min.js'
import 'gantt-schedule-timeline-calendar/dist/style.css'
import { ref, onMounted, onBeforeUnmount, watch, computed, defineProps } from 'vue'

const globalProps = defineProps({
  items: {
    type: Object,
    default: () => ({})
  },
  issues: {
    type: Object,
    default: () => ({})
  },
  dateFrom: {
    type: String,
    default: ''
  },
  limitDays: {
    type: Number,
    default: 30
  },
  columns: {
    type: Object,
    required: true
  },
  itemSlot: {
    type: Function,
    required: true
  },
  rowSlot: {
    type: Function,
    required: true
  }
})

const addDays = (startDate, days) => {
  const date = new Date(startDate)
  date.setDate(date.getDate() + days)
  return date
}

let gstc

onMounted(() => {
  window.GSTC = GSTC

  const GSTCID = GSTC.api.GSTCID
  const startDate = computed(() => GSTC.api.date(globalProps.dateFrom || new Date()))
  const endDate = computed(() =>
    GSTC.api.date(addDays(globalProps.dateFrom || new Date(), globalProps.limitDays)).endOf('day')
  )

  const columns = globalProps.columns

  function onItemClick(ev) {
    const itemElement = ev.target.closest('.gstc__chart-timeline-items-row-item')
    const itemId = itemElement.dataset.gstcid
    const item = gstc.api.getItem(itemId)
    console.log('Item click from template', item)
  }

  function chartTimelineItemsRowItemTemplate({
    className,
    labelClassName,
    styleMap,
    cache,
    shouldDetach,
    getContent,
    actions,
    slots,
    html,
    props
  }) {
    const detach = shouldDetach || !props || !props.item
    return cache(
      detach
        ? null
        : slots.html(
            'outer',
            html`
              <div
                class=${className}
                data-gstcid=${props.item.id}
                data-actions=${actions()}
                style=${styleMap.directive()}
                @click=${onItemClick}
              >
                ${slots.html(
                  'inner',
                  html` <div class=${labelClassName}>${slots.html('content', getContent())}</div> `
                )}
              </div>
            `
          )
    )
  }

  const months = [
    {
      zoomTo: 100, // we want to display this format for all zoom levels until 100
      period: 'month',
      periodIncrement: 1,
      format(context) {
        const { timeStart } = context
        return `${timeStart.format('MMMM')} ${timeStart.format('YYYY')}` // full list of formats: https://day.js.org/docs/en/display/format
      }
    }
  ]

  const weeks = [
    {
      zoomTo: 23, // we want to display this format for all zoom levels until 100
      period: 'week',
      periodIncrement: 1,
      format(context) {
        const { timeStart } = context
        return `${timeStart.format('DD')} week` // full list of formats: https://day.js.org/docs/en/display/format
      }
    }
  ]

  const days = [
    {
      zoomTo: 100, // we want to display this format for all zoom levels until 100
      period: 'day',
      periodIncrement: 1,
      main: true,
      format(context) {
        const { timeStart, className, vido } = context
        const result = vido.html`<div class="chart__date">${timeStart.format('DD')}</div>`

        return result
      }
    }
  ]

  /**
   * @type { import("gantt-schedule-timeline-calendar").Config }
   */
  const config = ref({
    licenseKey:
      '====BEGIN LICENSE KEY====\nU3X+PDGxKJT/Vqr+5vadLZG2Xu1bs50fNNR55m0OHHhfc65VdF7bZ/ePOdgH9s5xbvH2hCbey1+GOEQZGf7m3S4zsflWHrEc2CXxiIDYxZ0wXaEBmNqGCZLP3UzApExCCxw0gJTsodjtTqB1eWTO3CtMvXX1fljWzXoCX1VJ8pucir2IZ/AraFXR5XN3N3siiQW+CQeQ5ANCEQn3jmCnEnNdR7Y2xPFtLnHVNc1pEzuG7GCSIj7HmRg7HxWYeNFIkBnPXjcHwesnl2ZuE5ZRN6aEqWMlqxsybTwLF5Lc/8ElGaqU5XTjNdDtLsbFGa3raL9CvPe8QH83ZPW+mWHLVA==||U2FsdGVkX18HhG1oHNB1Sb2CXx953XJn0Gw/epPI68eYXFbv6O5FVIN50F+cjQ6pJX2i3pQ5tGA10KDw7TMmMPiHMKx50XI8ktA6ey8Nkfc=\nIJeZsbPulY0cXvBDf/W1f4oxNoFqtf1m7zsHWi+mdNIirIhPc1+LruwrUhhQtcaS639vsQsgfhDaXpDCOoeQNVhkHGmeuVK2JA6JNibqFagcVi9S4x3edD/AttfweHuRqEiUNODhO60IOJyDY1U+A2x7/JdO4ZFXtS6zHFmoKonmvCWjp3JY86ncfmNzj+2+PkEpzaxwQgpvYisvSGpAIDOTcXkzb6648J3ZHU+9eJkl0xBo5zR9VK7//7q1dGOpFZSW5gYSZ1hJLVZwJuPiQ0s1MZVIDsY+7w9hgE9MIcA6eV7kePrG1MWuGQGkv1pbqVLEQ4Cjw8/KhZ31YUhmRA==\n====END LICENSE KEY====',
    innerHeight: 700,
    //autoInnerHeight: true,
    plugins: [HighlightWeekends(), CalendarScroll()],
    list: {
      row: {
        height: 30
      },
      rows: globalProps.items,
      columns
    },
    chart: {
      calendarLevels: [months, weeks, days],
      time: {
        from: startDate.value.valueOf(),
        to: endDate.value.valueOf()
      },
      item: {
        minWidth: 30,
        height: 20
      },
      items: globalProps.issues
    },
    scroll: {
      // vertical: { precise: true },
      horizontal: { precise: true }
    },
    slots: {
      'chart-timeline-items-row-item': { content: [globalProps.itemSlot] },
      'list-column-row': { content: [globalProps.rowSlot] }
    },
    templates: {
      'chart-timeline-items-row-item': chartTimelineItemsRowItemTemplate
    }
  })

  let gstc
  let state
  function mountGSTC() {
    state = GSTC.api.stateFromConfig(config.value)
    const element = document.createElement('div')
    element.id = 'gstc'
    document.querySelector('#toolbox')?.after(element)
    gstc = GSTC({
      element,
      state
    })

    window.state = state
    window.gstc = gstc
  }

  mountGSTC()
  watch(config.value, () => {
    state.update('config.list.rows', () => globalProps.items)
    state.update('config.chart.items', () => globalProps.issues)
  })

  const html = GSTC.lithtml.html

  // function updateToolBox() {
  // const searchBoxHTML = html`<input type="text" @input=${searchRows} placeholder="Search" />`
  // const toolboxButtons = html` <div class="toolbox-row">
  //   <div class="toolbox-item">${searchBoxHTML}</div>
  // </div>`
  // GSTC.lithtml.render(toolboxButtons, document.getElementById('toolbox'))
  // }
  // updateToolBox()
})

onBeforeUnmount(() => {
  if (gstc) gstc.destroy()
})
</script>

<style lang="scss" scoped>
.chart-date {
  color: red;
}

.gstc-component {
  margin: 0;
  padding: 0;
}
.toolbox {
  padding: 10px;
}

.gstc-wrapper:deep(& .gstc) {
  background-color: black !important;
}

#gstc .gstc__chart-calendar-date {
  width: 30px;
}
</style>
