export default (context: any) => {
  class FormMethods {
    private static endpoints = context.$config.endpoints
    async sendReview(data: any) {
      const config = FormMethods.endpoints.form.sendReview
      return await context.$services.useAPI.request({ config, body: data })
    }

    async sendFeedback(data: any) {
      const config = FormMethods.endpoints.form.sendFeedback
      return await context.$services.useAPI.request({ config, body: data })
    }

    async sendRequest(data: any) {
      const config = FormMethods.endpoints.form.sendRequest
      return await context.$services.useAPI.request({ config, body: data })
    }
  }
  context.$api.form = new FormMethods()
}
