<template>
  <div class="section">
    <div>
      <div class="date-select">
        <VueDatePicker
          v-model="dateInputFrom"
          :enable-time-picker="false"
          :close-on-auto-apply="true"
          :clearable="false"
        />
        <VueDatePicker v-model="dateInputFrom" :enable-time-picker="false" :clearable="false" />
      </div>
      <div class="dates">
        <button @click="changeDatePage('prev')">Назад по датам</button>
        <button @click="changeDatePage('next')">Вперед по датам</button>
      </div>
      <GSTC
        v-if="!loading"
        v-bind="settingsGSTC.resourcesByUser"
        :key="count"
        :items="rowsData"
        :issues="itemsData"
        :date-from="dateFrom"
        :limit-days="dateLimit"
      />
      <!-- <button v-if="page > 1" @click="changeUsersPage('prev')">Назад</button>
      <button @click="changeUsersPage('next')">Вперед</button> -->
    </div>
  </div>
</template>

<script setup>
import { inject, ref, watch } from 'vue'
import GSTC from '@/components/GSTC.vue'

const dateInputFrom = ref(new Date())

watch(dateInputFrom, (value) => {
  if (value) {
    const year = value.getFullYear()
    const month = addZero(value.getMonth() + 1)
    const day = addZero(value.getDate())
    dateFrom.value = `${year}-${month}-${day}`

    console.log(dateFrom.value)
  }
})

const dateFrom = ref('2023-06-05')
const dateLimit = ref(10)

const rowsData = ref({})
const itemsData = ref({})
const count = ref(0)
const loading = ref(true)

const { resource: resourceService } = inject('services')
const { settingsGSTC } = inject('config')

const page = ref(0)

resourceService
  .getResourcesByUser({
    dateFrom: dateFrom.value,
    dateLimit: dateLimit.value,
    page: page.value,
    limit: 7
  })
  .then(({ rows, items }) => {
    rowsData.value = rows
    itemsData.value = items
    count.value++
    loading.value = false
  })

const changeUsersPage = (action) => {
  if (action === 'next') {
    page.value += 1
  } else {
    page.value -= 1
  }

  resourceService
    .getResourcesByUser({
      dateFrom: dateFrom.value,
      dateLimit: dateLimit.value,
      page: page.value,
      limit: 7
    })
    .then(({ rows, items }) => {
      rowsData.value = rows
      itemsData.value = items
      count.value++
      loading.value = false
    })
}

const addDays = (date, days) => {
  const newDate = new Date(date)
  newDate.setDate(newDate.getDate() + days)
  return newDate
}

const addZero = (value) => (value < 10 ? `0${value}` : value)

const changeDatePage = (action) => {
  let newDate = dateFrom.value
  if (action === 'next') {
    const a = addDays(dateFrom.value, dateLimit.value)
    const year = a.getFullYear()
    const month = addZero(a.getMonth() + 1)
    const day = addZero(a.getDate())
    newDate = `${year}-${month}-${day}`
  } else {
    const a = addDays(dateFrom.value, -dateLimit.value)
    const year = a.getFullYear()
    const month = addZero(a.getMonth() + 1)
    const day = addZero(a.getDate())
    newDate = `${year}-${month}-${day}`
  }

  dateFrom.value = newDate
  console.log(dateFrom.value)

  resourceService
    .getResourcesByUser({
      dateFrom: dateFrom.value,
      dateLimit: dateLimit.value,
      page: page.value,
      limit: 7
    })
    .then(({ rows, items }) => {
      rowsData.value = rows
      itemsData.value = items
      count.value++
      loading.value = false
    })
}
</script>

<style lang="scss" scoped>
.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dates {
  display: flex;
  justify-content: center;
  column-gap: 2rem;
  width: 50rem;
  margin: 5rem auto 5rem;

  button {
    border: none;
    background-color: #236692;
    color: white;
    border-radius: 1.2rem;
    width: 23rem;
    padding: 1.2rem;
    cursor: pointer;
  }
}

.date-select {
  display: flex;
  column-gap: 2rem;
  width: 50rem;
  margin: 5rem auto 5rem;
}
</style>
