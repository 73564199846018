export default (ctx: any) => {
  const constants = {
    fakeResourcesByUser: [
      {
        id: 'user-1',
        name: 'Арчаков Андрей',
        projects: [
          {
            id: 'project-1',
            name: 'Меланома',
            deadline: '2023-04-01',
            issues: [
              {
                id: 'issues-1',
                name: 'FD-1123: Создать верстку',
                deadline: '2023-03-15',
                timesheet: [
                  {
                    id: 'date-1',
                    date: '2023-03-16',
                    value: 3
                  },
                  {
                    id: 'date-2',
                    date: '2023-03-17',
                    value: 6
                  },
                  {
                    id: 'date-3',
                    date: '2023-03-18',
                    value: 1
                  },
                  {
                    id: 'date-4',
                    date: '2023-03-19',
                    value: 40
                  }
                ]
              },
              {
                id: 'issues-2',
                name: 'FD-1123: Создать верстку 2',
                deadline: '2023-03-16',
                timesheet: [
                  {
                    id: 'date-1',
                    date: '2023-03-16',
                    value: 3
                  },
                  {
                    id: 'date-2',
                    date: '2023-03-17',
                    value: 6
                  },
                  {
                    id: 'date-3',
                    date: '2023-03-18',
                    value: 1
                  },
                  {
                    id: 'date-4',
                    date: '2023-03-19',
                    value: 40
                  }
                ]
              }
            ]
          },
          {
            id: 'project-2',
            name: 'Меланома',
            total: 50,
            issues: [
              {
                id: 'issues-1',
                name: 'FD-1123: Создать верстку',
                timesheet: [
                  {
                    id: 'date-1',
                    date: '2023-03-16',
                    value: 3
                  },
                  {
                    id: 'date-2',
                    date: '2023-03-17',
                    value: 6
                  },
                  {
                    id: 'date-3',
                    date: '2023-03-18',
                    value: 1
                  },
                  {
                    id: 'date-4',
                    date: '2023-03-19',
                    value: 40
                  }
                ]
              },
              {
                id: 'issues-2',
                name: 'FD-1123: Создать верстку 2',
                timesheet: [
                  {
                    id: 'date-1',
                    date: '2023-03-16',
                    value: 3
                  },
                  {
                    id: 'date-2',
                    date: '2023-03-17',
                    value: 6
                  },
                  {
                    id: 'date-3',
                    date: '2023-03-18',
                    value: 1
                  },
                  {
                    id: 'date-4',
                    date: '2023-03-19',
                    value: 40
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        id: 'user-2',
        name: 'Алина Бахвалова',
        projects: [
          {
            id: 'project-1',
            name: 'Меланома',
            issues: [
              {
                id: 'issues-1',
                name: 'FD-1123: Создать верстку',
                timesheet: [
                  {
                    id: 'date-1',
                    date: '2023-03-16',
                    value: 3
                  },
                  {
                    id: 'date-2',
                    date: '2023-03-17',
                    value: 6
                  },
                  {
                    id: 'date-3',
                    date: '2023-03-18',
                    value: 1
                  },
                  {
                    id: 'date-4',
                    date: '2023-03-19',
                    value: 40
                  }
                ]
              },
              {
                id: 'issues-2',
                name: 'FD-1123: Создать верстку 2',
                timesheet: [
                  {
                    id: 'date-1',
                    date: '2023-03-16',
                    value: 3
                  },
                  {
                    id: 'date-2',
                    date: '2023-03-17',
                    value: 6
                  },
                  {
                    id: 'date-3',
                    date: '2023-03-18',
                    value: 1
                  },
                  {
                    id: 'date-4',
                    date: '2023-03-19',
                    value: 40
                  }
                ]
              }
            ]
          },
          {
            id: 'project-2',
            name: 'Меланома',
            total: 50,
            issues: [
              {
                id: 'issues-1',
                name: 'FD-1123: Создать верстку',
                timesheet: [
                  {
                    id: 'date-1',
                    date: '2023-03-16',
                    value: 3
                  },
                  {
                    id: 'date-2',
                    date: '2023-03-17',
                    value: 6
                  },
                  {
                    id: 'date-3',
                    date: '2023-03-18',
                    value: 1
                  },
                  {
                    id: 'date-4',
                    date: '2023-03-19',
                    value: 40
                  }
                ]
              },
              {
                id: 'issues-2',
                name: 'FD-1123: Создать верстку 2',
                timesheet: [
                  {
                    id: 'date-1',
                    date: '2023-03-16',
                    value: 3
                  },
                  {
                    id: 'date-2',
                    date: '2023-03-17',
                    value: 6
                  },
                  {
                    id: 'date-3',
                    date: '2023-03-18',
                    value: 1
                  },
                  {
                    id: 'date-4',
                    date: '2023-03-19',
                    value: 40
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        id: 'user-3',
        name: 'Вадим Царев',
        projects: []
      }
    ]
  }

  ctx.$config.constants = constants
}
