import { storeToRefs } from 'pinia'
import { useResourcesStore } from '@/store/resources'

export default (context: any) => {
  class ProjectService {
    private static projectMethods = context.$api.project

    private static findAndGetAnExistingProject(cursor = '') {
      const resourcesStore = useResourcesStore()
      const { projects }: any = resourcesStore
      const result = projects?.find((project: any) => project.cursor === cursor)
      return result
    }

    async getProjects(cursor = '') {
      const projectsPage = false // ProjectService.findAndGetAnExistingProject(cursor)
      const result = projectsPage || (await ProjectService.projectMethods.getProjects({ cursor }))?.data
      return result
    }
  }

  context.$services.project = new ProjectService()
}
