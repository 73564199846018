<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <p class="aside-text aside-text--large">LOG IN</p>
      <form action="">
        <img src="@/assets/icons/inet-logotype.svg" alt="" class="logo" />
        <div class="fields">
          <input type="text" placeholder="Login" />
          <input type="password" placeholder="Password" />
        </div>

        <div class="actions">
          <button type="button" class="login" @click="onLogin">Войти</button>
          <button type="button" class="fogot">Забыл(а) пароль? ВСПОМИНАЙ!</button>
        </div>
      </form>
      <p class="aside-text aside-text--big">Система учета времени</p>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'

const router = useRouter()
const onLogin = () => {
  router.push('/')
}
</script>

<style lang="scss" scoped>
.page-wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-image: url('@/assets/images/login/login-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  row-gap: 2rem;
  height: 100%;
  min-height: 100vh;

  @include tablet-desktop {
    padding: 3rem 5rem 5rem;
  }

  @include mobile {
    padding: 1.5rem 2.5rem 2.5rem;
  }
}

.aside-text {
  align-self: flex-start;
  text-align: left;
  font-family: 'Dela Gothic One';
  letter-spacing: 0.08rem;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.2;

  &--large {
    @include adaptive-font(9.6, 3.2);
    line-height: 120%;
  }

  &--big {
    @include adaptive-font(6.4, 2.4);
    line-height: 120%;
  }
}

form {
  padding: 5rem 3rem;
  background: #212121;
  filter: drop-shadow(0 0 2rem rgba(0, 0, 0, 0.12));
  border-radius: 0.8rem;
  width: 100%;
  max-width: 33.4rem;
}

.fogot {
  font-family: 'Inter';
  font-size: 1rem;
  line-height: 1.2rem;
  text-decoration-line: underline;
  color: #d2fc04;
  background: none;
  border: none;
  margin-top: 2rem;
}

input {
  width: 100%;
  background: none;
  border: none;
  border-bottom: 0.1rem solid #ffffff;
  font-family: 'Inter Light';
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #ffffff;
  padding-bottom: 0.5rem;
  outline: none;

  &::placeholder {
    font-family: 'Inter Light';
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: #ffffff;
    padding: 1rem;
  }
}

.fields {
  display: flex;
  flex-direction: column;
  row-gap: 5rem;
  margin-top: 5rem;
}

.actions {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
</style>
