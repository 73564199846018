export default (context: any) => {
  class IssuesMethods {
    private static endpoints = context.$config.endpoints

    async getIssues(params = {}) {
      return context.$services.useAPI.request({ config: IssuesMethods.endpoints.issues.getIssues(params) })
    }
  }

  context.$api.issues = new IssuesMethods()
}
