import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

import { adapters } from '@/adapters/'
import { api } from '@/api/'
import { config } from '@/config/'
import { services } from '@/services/'

import '@/assets/styles/index.scss'

const app = createApp(App)

app.component('VueDatePicker', VueDatePicker)

app.use(adapters)
app.use(config)
app.use(api)
app.use(services)
app.use(router)
app.use(createPinia())

app.mount('#app')
