import axios from 'axios'

class ResponseException extends Response {}

class AxiosService {
  async request({ config = {}, body = {}, file = null }) {
    try {
      const data = { ...(await AxiosService.prepareRequest({ config, body })) }
      return await axios.request(data)
    } catch (error: any) {
      console.log(error)
      // throw error
    }
  }

  private static async prepareRequest({
    config = {
      url: '',
      method: '',
      params: {},
      headers: {}
    },
    body = {
      _token: ''
    }
  }: {
    config: any
    body: any
  }) {
    const { url = '', method = '', params = {}, headers = {}, customServer = '' } = config
    const isProduction = true
    const currentServer = 'https://internal-structure.inet.digital/api/v1'
    const result = {
      url: customServer || currentServer + url,
      method,
      params,
      headers: {},
      processData: false,
      contentType: false,
      data: {}
    }

    if (body) {
      const formData = new FormData()
      for (const key in body) {
        formData.append(key, body[key])
      }
      result.data = formData
    }

    // const username = 'admin@local.com'
    // const password = 'password'

    // const credentials = Buffer.from(`${username}:${password}`).toString('base64')
    // const authHeader = `Basic ${credentials}`

    const access = localStorage.getItem('access')

    const headersResult = headers ? Object.assign(headers, {}) : {}
    headersResult['X-CSRF-TOKEN'] = body?._token || ''
    headersResult['x-requested-with'] = 'XMLHttpRequest'
    headersResult['content-type'] = 'application/x-www-form-urlencoded; charset=UTF-8'

    if (access) {
      headersResult['Authorization'] = `Bearer ${access}`
    }

    result.headers = headersResult
    return result
  }
}

export default AxiosService
