export default (context: any) => {
  class ProjectMethods {
    private static endpoints = context.$config.endpoints

    async getProjects(params = {}) {
      return context.$services.useAPI.request({ config: ProjectMethods.endpoints.project.getProjects(params) })
    }
  }

  context.$api.project = new ProjectMethods()
}
