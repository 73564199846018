import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import pageIndex from '@/pages/index.vue'
import pageLogin from '@/pages/login.vue'

const routes: Array<RouteRecordRaw> = [
  { path: '/', component: pageIndex },
  { path: '/login', component: pageLogin }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
