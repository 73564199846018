// TEMPLATE //
// const endpoints = {
//   form: {
//     sendReview: { url: '/reviews-contest/reviews/send', method: 'POST' },
//     sendFeedback: { url: '/feedback/send', method: 'POST' },
//     sendRequest: { url: '/requests/messages/send', method: 'POST' }
//   },
//   review: {
//     getReviews: ({ params = {} }: { params: any }) => {
//       return { url: '/reviews-contest/reviews/gallery', method: 'GET', params }
//     }
//   },
//   winners: {
//     getWinners: { url: '/reviews-contest/reviews/winners', method: 'GET' }
//   }
// }

export default (ctx: any) => {
  const endpoints = {
    project: {
      getProjects: (params = {}) => ({ url: '/projects', method: 'GET', params })
    },
    user: {
      getUsers: { url: '/users', method: 'GET' }
    },
    issues: {
      getIssues: (params = {}) => ({ url: '/issues', method: 'GET', params })
    },
    resources: {
      getResourcesByUser: (params = {}) => ({
        url: '/yt-users/get-resources-by-users',
        method: 'GET',
        params
      })
    }
  }

  //date.getTime() / 1000

  ctx.$config.endpoints = endpoints
}
