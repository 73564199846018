import ApiClientService from '@/services/modules/ApiClientService'
import TemplateService from '@/services/modules/TemplateService'
import ProjectService from '@/services/modules/ProjectService'
import UserService from '@/services/modules/UserService'
import IssuesService from '@/services/modules/IssuesService'
import ResourceService from '@/services/modules/ResourceService'

const servicesList = [TemplateService, ApiClientService, ProjectService, UserService, IssuesService, ResourceService]

export const services = (context: any) => {
  class ServicesLocator {}
  context.$services = new ServicesLocator()
  servicesList.forEach((fn) => fn(context))
  context.provide('services', context.$services)
}
