import TemplateMethods from '@/api/modules/TemplateMethods'
import ProjectMethods from '@/api/modules/ProjectMethods'
import UserMethods from '@/api/modules/UserMethods'
import IssuesMethods from '@/api/modules/IssuesMethods'
import ResourcesMethods from '@/api/modules/ResourcesMethods'

const apiList = [TemplateMethods, ProjectMethods, UserMethods, IssuesMethods, ResourcesMethods]

export const api = (context: any) => {
  class ApiLocator {}
  context.$api = new ApiLocator()
  apiList.forEach((fn) => fn(context))
  context.provide('api', context.$services)
}
