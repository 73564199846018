import SettingsGSTC from '@/config/modules/SettingsGSTC'
import Endpoints from '@/config/modules/Endpoints'
import Constatnts from '@/config/modules/Constants'

const configList = [SettingsGSTC, Endpoints, Constatnts]

export const config = (context: any) => {
  class ConfigLocator {}
  context.$config = new ConfigLocator()
  configList.forEach((fn) => fn(context))
  context.provide('config', context.$config)
}
