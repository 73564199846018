import ResourcesAdapters from '@/adapters/modules/ResourcesAdapters'

const adaptersList = [ResourcesAdapters]

export const adapters = (context: any) => {
  class AdaptersLocator {}
  context.$adapters = new AdaptersLocator()
  adaptersList.forEach((fn) => fn(context))
  context.provide('api', context.$adapters)
}
