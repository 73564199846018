export default (context: any) => {
  class UserService {
    private static userMethods = context.$api.user

    async getUsers() {
      return await UserService.userMethods.getUsers()
    }
  }

  context.$services.user = new UserService()
}
