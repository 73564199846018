export default (context: any) => {
  class ResourcesMethods {
    private static endpoints = context.$config.endpoints

    async getResourcesByUser(params = {}) {
      const config = ResourcesMethods.endpoints.resources.getResourcesByUser(params)
      return await context.$services.useAPI.request({ config })
    }
  }

  context.$api.resources = new ResourcesMethods()
}
