export default (context: any) => {
  class IssuesService {
    private static issuesMethods = context.$api.issues

    async getIssues(params = {}) {
      const result = await IssuesService.issuesMethods.getIssues(params)
      return result.data
    }
  }

  context.$services.issues = new IssuesService()
}
