export default (context: any) => {
  class FormService {
    private static formMethods = context.$api.form

    async sendReview(data = {}) {
      return await FormService.formMethods.sendReview(data)
    }

    async sendFeedback(data = {}) {
      return await FormService.formMethods.sendFeedback(data)
    }

    async sendRequest(data = {}) {
      return await FormService.formMethods.sendRequest(data)
    }
  }

  context.$services.form = new FormService()
}
