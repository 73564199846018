import AxiosService from '@/services/modules/apiServices/AxiosService'

class APIClient {
  client: any
  context: any

  constructor(client: any, context: any) {
    this.client = client
    this.context = context
  }

  async request(data = {}) {
    return await this.client.request(data)
  }
}

export default (context: any) => {
  context.$services.useAPI = new APIClient(new AxiosService(), context)
}
