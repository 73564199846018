import GSTC from 'gantt-schedule-timeline-calendar/dist/gstc.wasm.esm.min.js'

export default (ctx: any) => {
  const GSTCID = GSTC.api.GSTCID

  const settingsGSTC = {
    resourcesByUser: {
      columns: {
        data: {
          [GSTCID('label')]: {
            id: GSTCID('label'),
            data: 'label',
            sortable: 'label',
            expander: true,
            isHTML: false,
            width: 315,
            header: {
              content: 'Наименование '
            }
          },
          [GSTCID('deadline')]: {
            id: GSTCID('deadline'),
            data({ row, vido }: any) {
              return vido.html`<div style="text-align:center">${row.deadline}</div>`
            },
            width: 100,
            sortable: 'deadline',
            header: {
              content: 'DL'
            }
          },
          [GSTCID('totalTime')]: {
            id: GSTCID('totalTime'),
            data({ row, vido }: any) {
              return vido.html`<div style="text-align:center">${row.totalTime}</div>`
            },
            width: 100,
            sortable: 'totalTime',
            header: {
              content: 'Время'
            }
          }
        }
      },
      itemSlot: function itemSlot(vido: any, props: any) {
        const { html, onChange, update } = vido

        let description = ''
        let spent = ''
        onChange((newProps: any) => {
          props = newProps
          if (!props || !props.item) return
          description = props.item.description
          spent = props.item.spent
          update()
        })

        return (content: any) =>
          html` <div class="issue-text">
            <div class="issue-label" style="text-align: left;">${content}</div>
            <div class="issue-description" style="font-size:11px;margin-top:2px;color:#fffffff0;line-height:1em;">
              ${description}
            </div>
            <span class="issue-spent">${spent}</span>
          </div>`
      },
      rowSlot: function rowSlot(vido: any, props: any) {
        const { html, onChange, update, api } = vido
        onChange((newProps: any) => {
          props = newProps
          if (!props || !props.row) return
          update()
        })

        return (content: any) => {
          if (!props || !props.column) return content
          return api.sourceID(props.column.id) === 'label'
            ? html`<div class="row-content-wrapper" style="display:flex">
                <div class="row-content" style="flex-grow:1;">${content}</div>
              </div>`
            : content
        }
      }
    }
  }

  ctx.$config.settingsGSTC = settingsGSTC
}
