export default (context: any) => {
  class UserMethods {
    private static endpoints = context.$config.endpoints

    async getUsers() {
      return context.$services.useAPI.request({ config: UserMethods.endpoints.user.getUsers })
    }
  }

  context.$api.user = new UserMethods()
}
